import React, { useContext } from "react"
import { Col, Container, Image, Row } from "react-bootstrap"
import LogoWhite from "../images/logo1.png"
import FooterBg from "../images/footerbg.jpg"
import { Link } from "react-router-dom"
import FacebookIcon from "../images/facebook.svg"
import InstagramIcon from "../images/instagram.svg"
import TwitterIcon from "../images/twitter.svg"
import DarkModeToggle from "react-dark-mode-toggle"
import { AppDataContext } from "../services/appData"
const Footer = () => {
  const { isDarkMode, toggleTheme } = useContext(AppDataContext)

  // const toggleTheme = (value) => {
  //   setIsDarkMode(value)
  //   if (theme === "light") {
  //     setTheme("dark")
  //   } else {
  //     setTheme("light")
  //   }
  // }
  // useEffect(() => {
  //   localStorage.setItem("theme", theme)
  //   document.body.className = theme
  // }, [theme])

  return (
    <section className="footer" style={{ backgroundImage: `url(${FooterBg})` }}>
      <Container fluid className="widthSmall">
        <Row>
          <Col lg={4}>
            <Image src={LogoWhite} className="footLogo" alt="" />
            <p className="foot_ab">
              Lorem Ipsum has been the industry's standard dummy text ever since
              the 1500s, Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s,
            </p>
          </Col>
          <Col lg={5}>
            <h3>Links</h3>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about-us">About</Link>
              </li>
              <li>
                <Link>Virtual Events</Link>
              </li>
              <li>
                <Link to="/terms-and-conditions">Terms & Conditions</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
            </ul>

            <div className="expFlex">
              <div>
                <h3>Explore</h3>
                <ul>
                  <li>
                    <Link to="/pricing">Plans</Link>
                  </li>
                  <li>
                    <Link to="/blogs">Blogs</Link>
                  </li>
                </ul>
              </div>

              <div className="swtich_box">
                <h4>Switch to Dark Mode</h4>
                <DarkModeToggle
                  onChange={toggleTheme}
                  checked={isDarkMode}
                  size={60}
                />
              </div>
            </div>
          </Col>
          <Col lg={3}>
            <h3>Connect with us</h3>
            <div className="social_links">
              <Link>
                <Image src={FacebookIcon} alt="" />
              </Link>

              <Link>
                <Image src={InstagramIcon} alt="" />
              </Link>

              <Link>
                <Image src={TwitterIcon} alt="" />
              </Link>
            </div>
          </Col>
        </Row>
        <div className="copyright">
          <p>© 2023 ACCEL. All rights reserved.</p>
        </div>
      </Container>
    </section>
  )
}

export default Footer
