import React, { useState, useEffect } from "react";

import {
  Col,
  Container,
  Row,
  Card,
  Table,
  Image,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { Icon } from "@iconify/react";
import RightIcon from "../images/crossii.svg";
import CorssIconRed from "../images/crossii1.svg";
import * as API from "../../services/env";

const ScreeningOverviewDualUseExport = () => {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      This item cannot be shipped to the country indicated
    </Tooltip>
  );

  const [ShipperData, setShipperData] = useState("");
  const [consineeData, setconsineeData] = useState("");

  const [EndUserData, setEndUserData] = useState("");

  const [hazchemDetails, sethazchemDetails] = useState([]);
  const [hscodeData, sethscodeData] = useState("");
  const [DangerousGoods, setDangerousGoods] = useState([]);
  const getHsCodeSearch = async (req, res) => {
    const userData = localStorage.getItem("userDetail");
    const reference = localStorage.getItem("reference");
    const hscodeVerfication = localStorage.getItem("hsCodeVerficationData");
    var screeningType = localStorage.getItem("screeningType");

    sethazchemDetails(screeningType);
    const screeningId = localStorage.getItem("screeningId");
    if (userData) {
      var data = JSON.parse(userData);
      var dataReference = JSON.parse(reference);
      var hsCodeverfication = JSON.parse(hscodeVerfication);
      var screeningIds = JSON.parse(screeningId);

      getHsCodesearchDetail(screeningIds);
    }

    // if (userData) {
    //   var data = JSON.parse(userData);
    //   var dataReference = JSON.parse(reference);
    //   var hsCodeverfication = JSON.parse(hscodeVerfication);

    //   getHsCodesearchDetail(hsCodeverfication&&hsCodeverfication.screeningId)
    // }
  };

  const getHsCodesearchDetail = async (index) => {
    await fetch(`${API.BASE_URL}getScreenigTotalAALdeatil`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        ScreningId: index,
      }),
    })
      .then((response) => response.json())
      .then(async (res) => {
        if (res.status == true) {
          sethscodeData(
            res.hazchemDetails.length > 0 && res.hazchemDetails[0].hscodeType
          );
          setDangerousGoods(res.hazchemDetails);
          setShipperData(res.data[0].userType == "Shipper" ? res.data[0] : "");
          setconsineeData(
            res.data[1].userType == "Consignee" ? res.data[1] : ""
          );
          setEndUserData(res.data[2].userType == "End User" ? res.data[2] : "");

          // sethscodetype(res.hscodeData?res.hscodeData.hscodeType:'')
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    // getECCNcodesearch();
    getHsCodeSearch();
  }, []);

  return (
    <>
      <Helmet bodyAttributes={{ style: "background-color : #efefef" }} />
      <section className="py-3 overview_sec">
        <Container fluid className="widthSmall">
          <Row className="mb-lg-4 mb-md-4 mb-3">
            <Col md={12}>
              <div className="requestFlex">
                <h2 className="scheading">
                  Screening Overview : Re-export Country Control
                </h2>

                <Button
                  as={Link}
                  variant="warning"
                  size="md"
                  className="btnBack"
                  to={`/screening-overview/1`}
                >
                  <Icon icon="carbon:task-view" fontSize={18} />
                  Back to Screening Overview
                </Button>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12} className="tab_link">
              {/* {hazchemDetails!='Batch Screening'?
                   <Link to="/screeningoverview-RPS">RPS</Link>
              
              :<>
         
              
              </>} */}

              <Link to="/screeningoverview-RPS">RPS</Link>

              <Link to="/screeningoverview-HSCodeVerification">
                HS Code Verification
              </Link>
              <Link to="/screeningoverview-HSCodeSanctions">
                HS Code Sanctions
              </Link>
              <Link to="/screeningoverview-DualUseGoods">Dual-Use Goods</Link>
              <Link className="active" to="/screeningoverview-DualUseExport">
                Re-export Country Control
              </Link>
              <Link to="/screeningoverview-DangerousGoods">
                Dangerous Goods
              </Link>
              <Link to="/screeningoverview-PrecursorChemicals">
                Precursor Chemicals
              </Link>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Row>
                <Col md={12}>
                  <Card className="mb-3 mb-lg-4 mb-md-4 card-boxx card-boxx_mar">
                    <Card.Body className="newtabLe">
                      <Table bordered responsive>
                        <thead>
                          <tr>
                            <th style={{ minWidth: "130px" }}>Country</th>
                            <th style={{ minWidth: "130px" }}>
                              <div className="text-center">
                                Chemical and
                                <br /> biological
                                <br /> weapons
                              </div>
                              <div className="cxBox">
                                <span>CB1</span>
                                <span>CB2</span>
                                <span>CB3</span>
                              </div>
                            </th>
                            <th style={{ minWidth: "130px" }}>
                              <div className="text-center">
                                Nuclear Non
                                <br /> Proliferation
                              </div>
                              <div className="cxBox">
                                <span>NP1</span>
                                <span>NP2</span>
                              </div>
                            </th>
                            <th style={{ minWidth: "130px" }}>
                              <div className="text-center">
                                National
                                <br /> Security
                              </div>
                              <div className="cxBox">
                                <span>NS1</span>
                                <span>NS2</span>
                              </div>
                            </th>
                            <th style={{ minWidth: "130px" }}>
                              <div className="text-center">
                                Missile
                                <br /> Tech
                              </div>
                              <div className="cxBox">MT1</div>
                            </th>
                            <th style={{ minWidth: "130px" }}>
                              <div className="text-center">
                                Regional
                                <br /> Stability
                              </div>
                              <div className="cxBox">
                                <span>RS1</span>
                                <span>RS2</span>
                              </div>
                            </th>
                            <th style={{ minWidth: "130px" }}>
                              <div className="text-center">
                                Firearms
                                <br /> Convention
                              </div>
                              <div className="cxBox">
                                <span>FC1</span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {DangerousGoods.length > 0 &&
                          DangerousGoods[0].transhipment.length > 0
                            ? DangerousGoods.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <th>
                                      {item.transhipment[0] &&
                                        item.transhipment[0].country}
                                    </th>
                                    <th>
                                      <div className="cxBox mt-0">
                                        <span>
                                          {item.transhipment[0].CB == "0" ? (
                                            <OverlayTrigger
                                              placement="top"
                                              delay={{ show: 250, hide: 400 }}
                                              overlay={renderTooltip}
                                            >
                                              <Image
                                                src={CorssIconRed}
                                                alt=""
                                              />
                                            </OverlayTrigger>
                                          ) : (
                                            <Image src={RightIcon} alt="" />
                                          )}
                                        </span>
                                        <span>
                                          {item.transhipment[0].CB1 == "0" ? (
                                            <OverlayTrigger
                                              placement="top"
                                              delay={{ show: 250, hide: 400 }}
                                              overlay={renderTooltip}
                                            >
                                              <Image
                                                src={CorssIconRed}
                                                alt=""
                                              />
                                            </OverlayTrigger>
                                          ) : (
                                            <Image src={RightIcon} alt="" />
                                          )}
                                        </span>
                                        <span>
                                          {item.transhipment[0].CB2 == "0" ? (
                                            <OverlayTrigger
                                              placement="top"
                                              delay={{ show: 250, hide: 400 }}
                                              overlay={renderTooltip}
                                            >
                                              <Image
                                                src={CorssIconRed}
                                                alt=""
                                              />
                                            </OverlayTrigger>
                                          ) : (
                                            <Image src={RightIcon} alt="" />
                                          )}
                                        </span>
                                      </div>
                                    </th>
                                    <th>
                                      <div className="cxBox mt-0">
                                        <span>
                                          {item.transhipment[0].CB3 == "0" ? (
                                            <OverlayTrigger
                                              placement="top"
                                              delay={{ show: 250, hide: 400 }}
                                              overlay={renderTooltip}
                                            >
                                              <Image
                                                src={CorssIconRed}
                                                alt=""
                                              />
                                            </OverlayTrigger>
                                          ) : (
                                            <Image src={RightIcon} alt="" />
                                          )}
                                        </span>
                                        <span>
                                          {item.transhipment[0].CB4 == "0" ? (
                                            <OverlayTrigger
                                              placement="top"
                                              delay={{ show: 250, hide: 400 }}
                                              overlay={renderTooltip}
                                            >
                                              <Image
                                                src={CorssIconRed}
                                                alt=""
                                              />
                                            </OverlayTrigger>
                                          ) : (
                                            <Image src={RightIcon} alt="" />
                                          )}
                                        </span>
                                      </div>
                                    </th>
                                    <th>
                                      <div className="cxBox mt-0">
                                        <span>
                                          {item.transhipment[0].CB5 == "0" ? (
                                            <OverlayTrigger
                                              placement="top"
                                              delay={{ show: 250, hide: 400 }}
                                              overlay={renderTooltip}
                                            >
                                              <Image
                                                src={CorssIconRed}
                                                alt=""
                                              />
                                            </OverlayTrigger>
                                          ) : (
                                            <Image src={RightIcon} alt="" />
                                          )}
                                        </span>
                                        <span>
                                          {item.transhipment[0].CB6 == "0" ? (
                                            <OverlayTrigger
                                              placement="top"
                                              delay={{ show: 250, hide: 400 }}
                                              overlay={renderTooltip}
                                            >
                                              <Image
                                                src={CorssIconRed}
                                                alt=""
                                              />
                                            </OverlayTrigger>
                                          ) : (
                                            <Image src={RightIcon} alt="" />
                                          )}
                                        </span>
                                      </div>
                                    </th>
                                    <th>
                                      <div className="cxBox mt-0">
                                        <span>
                                          {item.transhipment[0].CB7 == "0" ? (
                                            <OverlayTrigger
                                              placement="top"
                                              delay={{ show: 250, hide: 400 }}
                                              overlay={renderTooltip}
                                            >
                                              <Image
                                                src={CorssIconRed}
                                                alt=""
                                              />
                                            </OverlayTrigger>
                                          ) : (
                                            <Image src={RightIcon} alt="" />
                                          )}
                                        </span>
                                      </div>
                                    </th>
                                    <th>
                                      <div className="cxBox mt-0">
                                        <span>
                                          {item.transhipment[0].CB8 == "0" ? (
                                            <OverlayTrigger
                                              placement="top"
                                              delay={{ show: 250, hide: 400 }}
                                              overlay={renderTooltip}
                                            >
                                              <Image
                                                src={CorssIconRed}
                                                alt=""
                                              />
                                            </OverlayTrigger>
                                          ) : (
                                            <Image src={RightIcon} alt="" />
                                          )}
                                        </span>
                                        <span>
                                          {item.transhipment[0].CB9 == "0" ? (
                                            <OverlayTrigger
                                              placement="top"
                                              delay={{ show: 250, hide: 400 }}
                                              overlay={renderTooltip}
                                            >
                                              <Image
                                                src={CorssIconRed}
                                                alt=""
                                              />
                                            </OverlayTrigger>
                                          ) : (
                                            <Image src={RightIcon} alt="" />
                                          )}
                                        </span>
                                      </div>
                                    </th>
                                    <th>
                                      <div className="cxBox mt-0">
                                        <span>
                                          {item.transhipment[0].CB10 == "0" ? (
                                            <OverlayTrigger
                                              placement="top"
                                              delay={{ show: 250, hide: 400 }}
                                              overlay={renderTooltip}
                                            >
                                              <Image
                                                src={CorssIconRed}
                                                alt=""
                                              />
                                            </OverlayTrigger>
                                          ) : (
                                            <Image src={RightIcon} alt="" />
                                          )}
                                        </span>
                                      </div>
                                    </th>
                                  </tr>
                                );
                              })
                            : <tr>
                            <td colSpan={12} style={{ textAlign: "center" }}>
                              Re-Export not applicable for this shipment
                            </td>
                          </tr>}

                          {/* <tr>
                            <th>Algeia</th>
                            <th>
                              <div className="cxBox mt-0">
                                <span>
                                  <Image src={RightIcon} alt="" />
                                </span>
                                <span>
                                  <Image src={RightIcon} alt="" />
                                </span>
                                <span>
                                  <Image src={RightIcon} alt="" />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="cxBox mt-0">
                                <span>
                                  <Image src={RightIcon} alt="" />
                                </span>
                                <span>
                                  <Image src={RightIcon} alt="" />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="cxBox mt-0">
                                <span>
                                  <Image src={RightIcon} alt="" />
                                </span>
                                <span>
                                  <Image src={RightIcon} alt="" />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="cxBox mt-0">
                                <span>
                                  <Image src={RightIcon} alt="" />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="cxBox mt-0">
                                <span>
                                  <Image src={RightIcon} alt="" />
                                </span>
                                <span>
                                  <Image src={RightIcon} alt="" />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="cxBox mt-0">
                                <span>
                                  <Image src={RightIcon} alt="" />
                                </span>
                              </div>
                            </th>
                          </tr>
                          <tr>
                            <th>Andorra</th>
                            <th>
                              <div className="cxBox mt-0">
                                <span>
                                  <Image src={RightIcon} alt="" />
                                </span>
                                <span>
                                  <Image src={RightIcon} alt="" />
                                </span>
                                <span>
                                  <Image src={RightIcon} alt="" />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="cxBox mt-0">
                                <span>
                                  <Image src={RightIcon} alt="" />
                                </span>
                                <span>
                                  <Image src={RightIcon} alt="" />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="cxBox mt-0">
                                <span>
                                  <Image src={RightIcon} alt="" />
                                </span>
                                <span>
                                  <Image src={RightIcon} alt="" />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="cxBox mt-0">
                                <span>
                                  <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltip}
                                  >
                                    <Image src={CorssIconRed} alt="" />
                                  </OverlayTrigger>
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="cxBox mt-0">
                                <span>
                                  <Image src={RightIcon} alt="" />
                                </span>
                                <span>
                                  <Image src={RightIcon} alt="" />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="cxBox mt-0">
                                <span>
                                  <Image src={RightIcon} alt="" />
                                </span>
                              </div>
                            </th>
                          </tr>
                          <tr>
                            <th>Aruba</th>
                            <th>
                              <div className="cxBox mt-0">
                                <span>
                                  <Image src={RightIcon} alt="" />
                                </span>
                                <span>
                                  <Image src={RightIcon} alt="" />
                                </span>
                                <span>
                                  <Image src={RightIcon} alt="" />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="cxBox mt-0">
                                <span>
                                  <Image src={RightIcon} alt="" />
                                </span>
                                <span>
                                  <Image src={RightIcon} alt="" />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="cxBox mt-0">
                                <span>
                                  <Image src={RightIcon} alt="" />
                                </span>
                                <span>
                                  <Image src={RightIcon} alt="" />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="cxBox mt-0">
                                <span>
                                  <Image src={RightIcon} alt="" />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="cxBox mt-0">
                                <span>
                                  <Image src={RightIcon} alt="" />
                                </span>
                                <span>
                                  <Image src={RightIcon} alt="" />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="cxBox mt-0">
                                <span>
                                  <Image src={RightIcon} alt="" />
                                </span>
                              </div>
                            </th>
                          </tr> */}
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ScreeningOverviewDualUseExport;
