import React, { useEffect, useState } from "react"
import Container from "react-bootstrap/Container"
import { Link, useLocation } from "react-router-dom"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import Image from "react-bootstrap/Image"
import Logo from "../images/logo1.png"
import DarkLogo from "../images/logo1.png"
import ArrowRight from "../images/arrowr.svg"

const Header = () => {
  const location = useLocation() // once ready it returns the 'window.location' object
  const [url, setUrl] = useState(null)
  useEffect(() => {
    setUrl(location.pathname)
  }, [location])

  return (
    <Navbar collapseOnSelect expand="lg" id="home" className="bg-primary">
      <Container fluid className="widthSmall">
        <Navbar.Brand as={Link} to="/">
          <Image src={Logo} className="lightLogo" />
          <Image src={DarkLogo} className="darkLogo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="ms-auto padTab">
            <Nav.Link className={url === "/" ? " active" : ""} as={Link} to="/">
              Home
            </Nav.Link>
            <Nav.Link
              className={url === "/about-us" ? " active" : ""}
              as={Link}
              to="/about-us"
            >
              About Us
            </Nav.Link>
            <Nav.Link
              className={url === "/pricing" ? " active" : ""}
              as={Link}
              to="/pricing"
            >
              Pricing
            </Nav.Link>
            <Nav.Link
              className={url === "/contact-us" ? " active" : ""}
              as={Link}
              to="/contact-us"
            >
              Contact Us
            </Nav.Link>
            <Nav.Link
              className={url === "/blogs" ? " active" : ""}
              as={Link}
              to="/blogs"
            >
              Blogs
            </Nav.Link>
            <Nav.Link className="joinBtn" as={Link} to="/login">
              Join <Image src={ArrowRight} alt="" />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Header
