import React, { useState, useEffect } from "react";

import { Col, Container, Row, Card, Form, Table, Image } from "react-bootstrap";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { Icon } from "@iconify/react";
import RightIcon from "../images/crossii.svg";
import CorssIconBlank from "../images/crossblank.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

import * as API from "../../services/env";

const ScreeningOverviewPrecursorChemicals = () => {
  const [ShipperData, setShipperData] = useState("");
  const [consineeData, setconsineeData] = useState("");

  const [EndUserData, setEndUserData] = useState("");

  const [hazchemDetails, sethazchemDetails] = useState([]);
  const [hscodeData, sethscodeData] = useState("");
  const [DangerousGoods, setDangerousGoods] = useState([]);
  const getHsCodeSearch = async (req, res) => {
    const userData = localStorage.getItem("userDetail");
    const reference = localStorage.getItem("reference");
    const hscodeVerfication = localStorage.getItem("hsCodeVerficationData");
    var screeningType = localStorage.getItem("screeningType");

    sethazchemDetails(screeningType);

    const screeningId = localStorage.getItem("screeningId");
    if (userData) {
      var data = JSON.parse(userData);
      var dataReference = JSON.parse(reference);
      var hsCodeverfication = JSON.parse(hscodeVerfication);
      var screeningIds = JSON.parse(screeningId);

      getHsCodesearchDetail(screeningIds);
    }

    // if (userData) {
    //   var data = JSON.parse(userData);
    //   var dataReference = JSON.parse(reference);
    //   var hsCodeverfication = JSON.parse(hscodeVerfication);

    //   getHsCodesearchDetail(hsCodeverfication&&hsCodeverfication.screeningId)
    // }
  };

  const getHsCodesearchDetail = async (index) => {
    await fetch(`${API.BASE_URL}getScreenigTotalAALdeatil`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        ScreningId: index,
      }),
    })
      .then((response) => response.json())
      .then(async (res) => {
        if (res.status == true) {
          sethscodeData(
            res.hazchemDetails.length > 0 && res.hazchemDetails[0].hscodeType
          );
          setDangerousGoods(res.hazchemDetails);
          setShipperData(res.data[0].userType == "Shipper" ? res.data[0] : "");
          setconsineeData(
            res.data[1].userType == "Consignee" ? res.data[1] : ""
          );
          setEndUserData(res.data[2].userType == "End User" ? res.data[2] : "");

          // sethscodetype(res.hscodeData?res.hscodeData.hscodeType:'')
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    // getECCNcodesearch();
    getHsCodeSearch();
  }, []);

  return (
    <>
      <Helmet bodyAttributes={{ style: "background-color : #efefef" }} />
      <section className="py-3 overview_sec">
        <Container fluid className="widthSmall">
          <Row className="mb-lg-4 mb-md-4 mb-3">
            <Col md={12}>
              <div className="requestFlex">
                <h2 className="scheading">
                  Screening Overview : Precursor Chemicals
                </h2>
  
                <Button
                  as={Link}
                  variant="warning"
                  size="md"
                  className="btnBack"
                  to={`/screening-overview/1`}
                >
                  <Icon icon="carbon:task-view" fontSize={18} />
                  Back to Screening Overview
                </Button>
              </div>
            </Col>
          </Row>
  
          <Row>
            <Col md={12} className="tab_link">
              <Link to="/screeningoverview-RPS">RPS</Link>
              <Link to="/screeningoverview-HSCodeVerification">
                HS Code Verification
              </Link>
              <Link to="/screeningoverview-HSCodeSanctions">
                HS Code Sanctions
              </Link>
              <Link to="/screeningoverview-DualUseGoods">Dual-Use Goods</Link>
              <Link to="/screeningoverview-DualUseExport">
                Re-export Country Control
              </Link>
              <Link to="/screeningoverview-DangerousGoods">Dangerous Goods</Link>
              <Link
                className="active"
                to="/screeningoverview-PrecursorChemicals"
              >
                Precursor Chemicals
              </Link>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              {hscodeData&&hscodeData?.some((item) => item.Precursor === "Yes") ? (
                <Table>
                  <thead>
                    <tr>
                    <th>Goods Description</th>
                    <th>HS Code Search</th>
                      <th>Chemical Or Dangerous Good</th>
                      <th>Dual Use Export Controlled Goods?</th>
                      <th>Export Licence Required</th>
                    </tr>
                  </thead>
                  <tbody>
                    {hscodeData
                      .filter((item) => item.Precursor === "Yes")
                      .map((item, index) => (
                        <tr key={index}>
                          <td>{item.goodDescription}</td>
                          <td>{item.hsCode}</td>

                          <td>{item.chemicalDangerios}</td>
                          <td>{item.DualUseExport}</td>
                          <td>
                            {item.licines === "Yes" ? (
                              <FontAwesomeIcon
                                icon={faCheck}
                                style={{ color: "green", marginLeft: 5 }}
                              />
                            ) : item.licines === "No" ? (
                              <FontAwesomeIcon
                                icon={faTimes}
                                style={{ color: "red", marginLeft: 5 }}
                              />
                            ) : (
                              "N/A"
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              ) : (
                <div className="text-center">No Data Found</div>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
  
};

export default ScreeningOverviewPrecursorChemicals;
