import React, { useState, useEffect } from "react";
import Image from "react-bootstrap/Image";
import LoginBG from "../../images/loginbg.png";
import Logo from "../../images/noImage.png";
import Logo1 from "../../images/logo1.png";
import { Button, Form } from "react-bootstrap";
import CustomForm from "../../customcomponents/CustomForm";
import { Link } from "react-router-dom";
import * as SERVICE from "../../../../src/services/index";
import * as API from "../../../../src/services/env";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EyeOffIcon from "../../images/iconmonstr-eye-off-thin.svg";
import EyeOnIcon from "../../images/iconmonstr-eye-thin.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppContext } from "../AppContext";
var userDataObject = "";

const SignUp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { appState } = useAppContext();

  // Access the data from the context
  const { prize } = appState;
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [confirmPass, setconfirmPass] = useState("");

  const [emailError, setemailError] = useState(0);
  const [passwordError, setpasswordError] = useState(0);
  const [confirmPassError, setconfirmPassError] = useState(0);

  const [Submit, setSubmit] = useState(false);
  const [errorCount, seterrCount] = useState(0);
  const [subdomain, setSubdomain] = useState(null);

  const [domain, setdomain] = useState("");

  const togglePasswordVisibility = () => {
    setpassword(!password);
  };

  // Toggle confirm password visibility
  const toggleConfirmPassVisibility = () => {
    setconfirmPass(!confirmPass);
  };

  const onProfileValidation = async () => {
    let errorCount = 0;
    if (SERVICE.hasError(email)) {
      errorCount++;
      let msg = "Please enter your email.";
      setemailError(msg);
    } else {
      setemailError(null);
    }
    if (SERVICE.hasError(password)) {
      errorCount++;
      let msg = "Please enter your password.";

      setpasswordError(msg);
    } else if (password) {
      const strongRegex = new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
      );
      if (!password.match(strongRegex)) {
        errorCount++;
        let msg1 =
          "Password length should be minimum 8 characters and contain atleast 1 number, 1 uppercase, 1 lowercase and 1 special character from these(@#$!%*^?&).";
        setpasswordError(msg1);
      } else {
        setpasswordError(null);
      }
    } else {
      setpasswordError(null);
    }
    // if (SERVICE.hasError(password)) {
    //   errorCount++;
    //   let msg = "Please enter your password.";
    //   setpasswordError(msg);
    // } else {
    //   setpasswordError(null);
    // }

    if (SERVICE.hasError(confirmPass)) {
      errorCount++;
      let msg = "Please re-enter your password.";
      setconfirmPassError(msg);
    } else {
      setconfirmPassError(null);
    }

    if (errorCount > 0) return seterrCount(errorCount);
    else seterrCount(0);
  };
  const onSubmit = async () => {
    setSubmit(true);

    onProfileValidation();
    if (errorCount > 0) return;
    // await detectWhitespace(domain.trim());
    if (confirmPass != password) {
      return toast("Confirm password is wrong");
    }

    let payload = {
      email: email,
      password: password,
      confirmpassword: confirmPass,
      username: subdomain
    };
    if (prize === undefined) {
      return toast("Please Select your package");
    }
    navigate("/UserDomain", {
      state: {
        email: email,
        password: password,
        confirmpassword: confirmPass,
        prize: prize,
      },
    });

   
    // await fetch(`${API.BASE_URL}updateSignup`, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Accept: "application/json",
    //   },
    //   // mode: "cors",
    //   body: JSON.stringify({
    //     email: email,
    //     password: password,
    //     confirmpassword: confirmPass,
    //     username: subdomain,
    //   }), // body data type must match "Content-Type" header
    // })
    //   .then((response) => response.json())
    //   .then((res) => {
    //     console.log("res", res);
    //     if (res.status == true) {
    //       // window.location.href = "/accel/UserDomain";
    //       // window.location.href = "/accel/login";
    //     } else {
    //     }
    //   })
    //   .catch((error) => {});
  };

  useEffect(() => {
    const host = window.location.hostname;
    const arr = host.split(".")[0];
    if (arr.length > 0) setSubdomain(arr);
  }, []);

  // if (subdomain) {
  //   let payload = {
  //     username: subdomain,
  //   };
  //   console.log("payload", payload);
  //   fetch(`${API.BASE_URL}fetchUserDomain`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //     // mode: "cors",
  //     body: JSON.stringify({
  //       username: subdomain,
  //     }), // body data type must match "Content-Type" header
  //   })
  //     .then((response) => response.json())
  //     .then((res) => {
  //       console.log("res", res);
  //       if (res.status == true) {
  //         setdomain(res.data[0].userName);
  //         // window.location.href = "/accel/login";
  //       } else {
  //       }
  //     })
  //     .catch((error) => {});
  // }
  // console.log("userDataObject", domain);

  useEffect(() => {
    onProfileValidation();
  }, [email, password, confirmPass]);

  return (
    <section className="login_box py-2">
      <Image src={Logo1} className="img-fluid logo1" />
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-md-6 hideloginimg">
            <Image src={LoginBG} className="img-fluid login_bg" />
          </div>
          <div className="col-md-6">
            <div className="loginBody">
              <Form>
                <Image src={Logo} className="img-fluid logo" />
                <h1>Hello, Welcome 👋</h1>
                <p className="para">
                  Enter your mail and create a strong password
                </p>

                <CustomForm
                  color={"#222528"}
                  controlText="email"
                  type="text"
                  lableText="Email"
                  placeholderText="Enter your mail"
                  onChange={(e) => setemail(e.target.value)} // set the onChange handler
                  value={email}
                />
                {emailError && Submit ? (
                  <div
                    style={{
                      fontFamily: "AvenirLTProLight",
                      color: "#FF0000",
                      opacity: 0.6,
                    }}
                  >
                    {emailError}
                  </div>
                ) : null}
                <CustomForm
                  color={"#222528"}
                  controlText="password"
                  type="password"
                  lableText="Password"
                  placeholderText="Enter your password"
                  onChange={(e) => setpassword(e.target.value)} // set the onChange handler
                  value={password}
                  isPassword={true}
                  toggleVisibility={togglePasswordVisibility}
                  isVisible={password}
                />

                {passwordError && Submit ? (
                  <div
                    style={{
                      fontFamily: "AvenirLTProLight",
                      color: "#FF0000",
                      opacity: 0.6,
                    }}
                  >
                    {passwordError}
                  </div>
                ) : null}

                <CustomForm
                  color={"#222528"}
                  controlText="password"
                  type="password"
                  lableText="Confirm Password"
                  placeholderText="Confirm your password"
                  onChange={(e) => setconfirmPass(e.target.value)} // set the onChange handler
                  value={confirmPass}
                />
                {confirmPassError && Submit ? (
                  <div
                    style={{
                      fontFamily: "AvenirLTProLight",
                      color: "#FF0000",
                      opacity: 0.6,
                    }}
                  >
                    {confirmPassError}
                  </div>
                ) : null}
                <div className="text-end">
                  {/* <Link>Get help signing up?</Link> */}
                </div>
                <Link
                  // to="/login"
                  className="btn btn-primary my-3 mb-4 my-mb-3 mb-md-3 my-lg-4 mb-lg-5 "
                  onClick={(e) => {
                    e.preventDefault(); // Prevent the default behavior of the link
                    onSubmit(); // Call your onSubmit function
                    // Add logic here to navigate to "/login" if the form is valid
                  }}
                >
                  Continue
                </Link>
                <ToastContainer />

                <p className="text-center">
                  Do you already have an account? <Link to="/login">Login</Link>
                </p>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignUp;
