import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Button, Image, Form, Spinner } from "react-bootstrap";
import LoginBG from "../images/loginbg.png";
import Logo1 from "../images/logo1.png";
import Logo from "../images/logo.png";
import { Link } from "react-router-dom";
import MasterCard from "../images/mastercard.png";
import { useAppContext } from "./AppContext";
import { useLocation, useNavigate } from "react-router-dom";
import * as API from "../../services/env";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SubscriptionUpdate = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const { appState } = useAppContext();

  const { userData } = location.state || {};
  const { prize } = location.state || {};
  const [loader, setloader] = useState(false);
  // var userEmail = email;


  const searchParams = new URLSearchParams(location.search);
  const [email, setemail] = useState("");
  const [userid, setuserid] = useState("");
  const [Carddeati, setCarddeati] = useState([]);
  const [CardDeatil, setCardDeatil] = useState("");
  const [RadioStatus, setRadioStatus] = useState(null);
  const [checkData, setcheckData] = useState(false);

  // Get the 'prize' and 'userData' values from the query string
  // const prize = searchParams.get("prize");
  // const userData = searchParams.get("userData");
  // Access the data from the context
  const stripe = useStripe();
  const elements = useElements();

  const cardElementOptions = {
    style: {
      base: {
        fontSize: "16px",
        fontFamily: "Arial, sans-serif",
        height: "50px",
        color: "#424770",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#9e2146",
      },
      variables: {
        borderRadius: "0px",
      },
    },
  };

  const [cardHide, setCardShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const cardToggle = () => {
    setCardShow(!cardHide);
  };

  // console.log("chrck token =====<", token.token.id);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    try {
      // Create PaymentMethod

      // Access card details from paymentMethod

      if (RadioStatus === null) {
        if (!stripe || !elements) {
          return;
        }

        const cardElement = elements.getElement(CardElement);
        const { paymentMethod, error } = await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
        });
        var token = await stripe.createToken(cardElement);
        if (error) {
          throw new Error(error.message);
        }
        const cardDetails = paymentMethod.card;

        let payload = {
          cardNum: cardDetails.last4,
          userid: userData,
          userEmail: email,
          priceId: prize,
          CustomerToken: token.token.id,
          // username: userDataObject.userName,
        };

        await fetch(`${API.BASE_URL}add_card`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          // mode: "cors",
          body: JSON.stringify(payload),
        })
          .then((response) => response.json())
          .then(async (res) => {
            if (res.status == true) {
              toast("Subscription renewal successfully checked.");
              await localStorage.setItem(
                "userDetail",
                JSON.stringify(res.updateddeatil)
              );
              // window.location.reload();
              // getCardDeatil();
              setTimeout(() => {
                navigate("/dashboard");
              }, 3000);
            } else if (
              res.msg == "Either your domain, email or password is wrong"
            ) {
            }
            if (res.msg == "Card already exists") {
              toast("Card already exists, please use another.");
              // await localStorage.setItem("token", JSON.stringify(res.token));
              // toast("Your domain created successfully");
              // setloginAndTheme(true, "light");
  
              // navigate("/dashboard");
              // history.push('/accel/dashboard');
              // browserHistory.push("/accel/dashboard");
              // window.location.href = "/accel/dashboard";
            }
          })
          .catch((error) => {});
      } else {
        let payload = {
          cardNum: CardDeatil.last4,
          userid: userData,
          priceId: prize,
          userEmail: email,
          type: "updateSubscription",
          // username: userDataObject.userName,
        };

        await fetch(`${API.BASE_URL}getPaymentBooking_Detail`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          // mode: "cors",
          body: JSON.stringify(payload),
        })
          .then((response) => response.json())
          .then(async (res) => {
            if (res.status == true) {
              toast("Subscription renewal successfully checked.");
              await localStorage.setItem(
                "userDetail",
                JSON.stringify(res.updateddeatil)
              );
              setTimeout(() => {
                navigate("/dashboard");
              }, 3000);
            } else if (
              res.msg == "Either your domain, email or password is wrong"
            ) {
              // toast("please check your domain, email or password");
            }
          })
          .catch((error) => {});
      }

      setLoading(false);
      setCardShow(false);
    } catch (error) {
      console.error(error.message);
      setLoading(false);
    }
  };
  const getCardDeatil = async (userid) => {
    setloader(true);
    const payload = {
      userid: userid,
    };

    await fetch(`${API.BASE_URL}getCardList`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      // mode: "cors",
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then(async (res) => {
        setloader(false);
        if (res.status == true) {
          setCarddeati(res.data);
        }
      })
      .catch((error) => {});
  };
  useEffect(() => {
    setcheckData(true);

    const getUserDeatil = async () => {
      const payload = {
        id: userData,
      };

      await fetch(`${API.BASE_URL}fetchUserData`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        // mode: "cors",
        body: JSON.stringify(payload),
      })
        .then((response) => response.json())
        .then(async (res) => {
          if (res.status == true) {
            

            setuserid(res.data[0]._id);
            setemail(res.data[0].email);
            await localStorage.setItem(
              "userDetail",
              JSON.stringify(res.data[0])
            );
            getCardDeatil(res.data[0]._id);

            // await localStorage.setItem("token", JSON.stringify(res.token));
            // toast("Your domain created successfully");
            // setloginAndTheme(true, "light");

            // navigate("/dashboard");
            // history.push('/accel/dashboard');
            // browserHistory.push("/accel/dashboard");
            // window.location.href = "/accel/dashboard";
          }
          
        })
        .catch((error) => {});
    };

    getUserDeatil();
  }, [checkData]);
  return (
    <section className="login_box login_boxSubs py-2">
      <Image src={Logo1} className="img-fluid logo1" />
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-md-6 hideloginimg">
            <Image src={LoginBG} className="img-fluid login_bg" />
          </div>
          <div className="col-md-6">
            <div className="loginBody">
              <form onSubmit={(e) => handleSubmit(e)}>
                <Image src={Logo} className="img-fluid logo" />
                <h1>Card Details</h1>

                {loader ? (
                  <div className="text-center mb-3">
                    <Spinner variant="primary" animation="border" />
                  </div>
                ) : (
                  <>
                
                    {["radio"].map((type) => (
                      <div key={`default-${type}`} className="allCards">
                        {Carddeati.map((item, index) => {
                          const { last4, brand } = item;
                          return (
                            <div key={index}>
                              <Form.Check
                                onChange={(e) => {
                                  setcheckData(true);

                                  setCardDeatil(item);
                                  setRadioStatus(e.target.value);
                                }}
                                type={type}
                                name="cardnew"
                                id={`reverse-${type}-1`}
                                reverse
                                label={
                                  <div>
                                    <Image
                                      src={MasterCard}
                                      className="img-fluid cimg"
                                    />
                                    {/* <span>`Axis Bank **** **** **** 8395`</span> */}
                                    <span
                                      style={{ textTransform: "capitalize" }}
                                    >{`${brand} **** **** **** ${last4}`}</span>

                                    {/* <span>`Axis Bank **** **** **** 8395`</span> */}
                                  </div>
                                }
                              />
                            </div>
                          );
                        })}

                        {/* <Form.Check
                        type={type}
                        name="cardnew"
                        id={`reverse-${type}-2`}
                        reverse
                        label={
                          <div>
                            <Image src={MasterCard} className="img-fluid cimg" />
                            <span>Axis Bank **** **** **** 8395</span>
                          </div>
                        }
                      />
                      <Form.Check
                        type={type}
                        name="cardnew"
                        id={`reverse-${type}-3`}
                        reverse
                        label={
                          <div>
                            <Image src={MasterCard} className="img-fluid cimg" />
                            <span>Axis Bank **** **** **** 8395</span>
                          </div>
                        }
                      /> */}
                      </div>
                    ))}
                  </>
                )}

                {/* {cardHide && ( */}
                <CardElement
                  className="cardStripe"
                  options={cardElementOptions}
                />
                {/* )} */}

                {/* <Link onClick={cardToggle} className="addNew">
                    + Add New
                  </Link> */}

                <Button
                  type="submit"
                  className="btn btn-primary my-3 mb-4 my-mb-3 mb-md-3 my-lg-4 mb-lg-5"
                  disabled={loading}
                >
                  {loading ? "Submitting..." : "Submit"}
                </Button>
                <ToastContainer/>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SubscriptionUpdate;
