import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { Row, Button, Col } from "react-bootstrap";
import { Icon } from "@iconify/react";
import Select from "react-select";

const HSCodeRow = ({
  row,
  index,
  handleChange,
  retrieveHsCodeSearch,
  optionhsCode,
  optionDesc,
  handleDeleteRow,
  deleteIconhide,
}) => {
  const [selectedOptionhsCode, setSelectedOptionhsCode] = useState(null);
  const [selectedOptionDesc, setSelectedOptionDesc] = useState(null);

  // Check if dangerous goods condition is met (first two digits between 28 and 38)

if(typeof row.hsCode === "string"){
  var isDangerousGood =
    row.hsCode &&
    parseInt(row.hsCode.substring(0, 2)) >= 28 &&
    parseInt(row.hsCode.substring(0, 2)) <= 38;
}else{
  var isDangerousGood = (() => {
    if (!row.hsCode) return null; // No HS code, return null
    const hsCode =
      typeof row.hsCode === "number" ? row.hsCode : parseInt(row.hsCode, 10);
    const firstTwoDigits = Math.floor(hsCode / 100);
    return firstTwoDigits >= 28 && firstTwoDigits <= 38;
  })();
  
}
  
useEffect(() => {
  const newValue = isDangerousGood ? "Yes" : "No";
  if (row.selectedOption !== newValue) {
    handleChange(index, "selectedOption", newValue);
  }
}, [isDangerousGood, index, row.selectedOption]);


  const ophscode = {
    value: row.hsCode,
    label: row.hsCode,
    hsCode: row.hsCode,
    description: row.hsCode,
  };
  const opdes = {
    value: row.goodDescription,
    label: row.goodDescription,
    hsCode: row.goodDescription,
    description: row.goodDescription,
  };

  return (
    <div key={index}>
      <Row className="mb-3 mb-lg-2">
        <Col md={12}>
          <div className="scree_btn scree_btnShrink">
            <div className="w-100">
              <Form.Label style={{ color: "#222528" }}>
                {"HS Code Search (6 digits) *"}
              </Form.Label>
              <Select
                value={
                  row.hsCode && selectedOptionhsCode == null
                    ? ophscode
                    : selectedOptionhsCode
                }
                onChange={(e) => {
                  if (e) {
                    // If an option is selected
                    handleChange(index, "hsCode", e.value);
                    setSelectedOptionhsCode(e);
              
                    // Update description based on selected HS Code
                    const matchedDescription = optionhsCode.find(
                      (opt) => opt.hsCode === e.value
                    );
                    if (matchedDescription) {
                      const descOption = {
                        value: matchedDescription.description,
                        label: matchedDescription.description,
                        hsCode: matchedDescription.hsCode,
                      };
                      setSelectedOptionDesc(descOption);
                      handleChange(index, "goodDescription", matchedDescription.description); // Sync description
                    }
                  } else {
                    // If selection is cleared
                    handleChange(index, "hsCode", "");
                    setSelectedOptionhsCode(null);
                    setSelectedOptionDesc(null); // Clear description
                    handleChange(index, "goodDescription", ""); // Reset description in form state
                  }
                }}
                options={optionhsCode}
                placeholder="If HS Code is unknown..."
                isClearable
                isSearchable
                onInputChange={(e) => {
                  retrieveHsCodeSearch("hsCode", e);
                }}
                onMenuOpen={() => {
                  retrieveHsCodeSearch("hsCode", "");
                }}
              />
            </div>

            <span className="orHeading mx-3"> OR </span>
            <div className="w-100">
              <Form.Label style={{ color: "#222528" }}>
                {"Goods Description *"}
              </Form.Label>
              {/* <Select
                value={
                  row.goodDescription && selectedOptionhsCode == null
                    ? opdes
                    : selectedOptionDesc
                }
                onChange={(e) => {
                  handleChange(index, "goodDescription", e.value);
                  setSelectedOptionDesc(e);

                  if (optionDesc.length > 0) {
                    const opdesc = optionDesc.find(
                      (oe) => oe.description === e.value
                    );
                    const ophsCode = {
                      value: opdesc.hsCode,
                      label: opdesc.hsCode,
                      hsCode: opdesc.hsCode,
                      description: opdesc.description,
                    };
                    setSelectedOptionhsCode(ophsCode);
                  }
                }}
                options={optionDesc}
                placeholder="If good description is unknown..."
                isSearchable
                onInputChange={(e) => {
                  retrieveHsCodeSearch("description", e);
                }}
                onMenuOpen={() => {
                  retrieveHsCodeSearch("description", "");
                }}
              /> */}
              <Select
                value={
                  row.goodDescription && selectedOptionhsCode == null
                    ? opdes
                    : selectedOptionDesc
                }
                onChange={(e) => {
                  if (e) {
                    // If a description is selected
                    handleChange(index, "goodDescription", e.value);
                    setSelectedOptionDesc(e);

                    if (optionDesc.length > 0) {
                      const opdesc = optionDesc.find(
                        (oe) => oe.description === e.value
                      );
                      const ophsCode = {
                        value: opdesc.hsCode,
                        label: opdesc.hsCode,
                        hsCode: opdesc.hsCode,
                        description: opdesc.description,
                      };
                      setSelectedOptionhsCode(ophsCode); // Sync HS Code
                      handleChange(index, "hsCode", opdesc.hsCode); // Update HS Code in form state
                    }
                  } else {
                    // If description is cleared
                    handleChange(index, "goodDescription", "");
                    setSelectedOptionDesc(null); // Clear description
                    setSelectedOptionhsCode(null); // Clear HS Code
                    handleChange(index, "hsCode", ""); // Reset HS Code in form state
                  }
                }}
                options={optionDesc}
                placeholder="If good description is unknown..."
                isSearchable
                isClearable // Allow clearing the selection
                onInputChange={(e) => {
                  retrieveHsCodeSearch("description", e);
                }}
                onMenuOpen={() => {
                  retrieveHsCodeSearch("description", "");
                }}
              />
            </div>

            <div className="scree_btn scree_btn1 wWidth ms-4">
              <Form.Group className="mb-3">
                <Form.Label>Chemical Or Dangerous Good *</Form.Label>
                <select
                  className="form-control"
                  value={
                    isDangerousGood === true
                      ? "Yes"
                      : isDangerousGood === false
                      ? "No"
                      : "" // Default to empty string if undefined or null
                  }
                  onChange={(e) => {
                    const value = e.target.value;
                    handleChange(index, "selectedOption", value);
                  }}
                  disabled={isDangerousGood !== null} // Disable if dangerous good condition is met
                >
                  <option value="">Select...</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </Form.Group>
            </div>

            <div className="scree_btn scree_btn1 wWidth">
              <Form.Group className="mb-3">
                <Form.Label>Dual Use Export Controlled Goods *</Form.Label>
                <select
                  className="form-control"
                  value={row.DualSelectedOption || row.DualUseExport}
                  onChange={(e) =>
                    handleChange(index, "DualSelectedOption", e.target.value)
                  }
                  disabled={true}
                >
                  <option value="">Select...</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </Form.Group>
            </div>

            {deleteIconhide !== true ? (
              <>
                {index !== 0 ? (
                  <Button
                    variant="primary"
                    size="md"
                    className="mr-1"
                    style={{ minWidth: 100 }}
                    onClick={() => handleDeleteRow(index)}
                  >
                    <Icon icon="pixelarticons:text-add" fontSize={18} />
                    Delete
                  </Button>
                ) : null}
              </>
            ) : null}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default HSCodeRow;
