import React, { useState, useEffect } from "react";

import { Col, Container, Row, Card, Image, Table } from "react-bootstrap";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { Icon } from "@iconify/react";
import LogoNew from "../images/logonew.png";
import LogoNew1 from "../images/logonew1.png";
import LogoNew2 from "../images/logonew2.png";
import { Form } from "react-bootstrap";
import RightIcon from "../images/crossii.svg";
import CorssIconBlank from "../images/crossblank.svg";
import * as API from "../../services/env";
import * as PIC from "../../services/env";

const ScreeningOverviewDangerousGoods = () => {
  const [ShipperData, setShipperData] = useState("");
  const [consineeData, setconsineeData] = useState("");

  const [EndUserData, setEndUserData] = useState("");

  const [hazchemDetails, sethazchemDetails] = useState([]);
  const [hscodeData, sethscodeData] = useState("");
  const [DangerousGoods, setDangerousGoods] = useState([]);
  const getHsCodeSearch = async (req, res) => {
    const userData = localStorage.getItem("userDetail");
    const reference = localStorage.getItem("reference");
    const hscodeVerfication = localStorage.getItem("hsCodeVerficationData");

    var screeningType = localStorage.getItem("screeningType");

    sethazchemDetails(screeningType);
    const screeningId = localStorage.getItem("screeningId");
    if (userData) {
      var data = JSON.parse(userData);
      var dataReference = JSON.parse(reference);
      var hsCodeverfication = JSON.parse(hscodeVerfication);
      var screeningIds = JSON.parse(screeningId);

      getHsCodesearchDetail(screeningIds);
    }

    //     if (userData) {
    //       var data = JSON.parse(userData);
    //       var dataReference = JSON.parse(reference);
    //       var hsCodeverfication = JSON.parse(hscodeVerfication);
    //  setEndUserData(hsCodeverfication&&hsCodeverfication.screeningId)

    //       getHsCodesearchDetail(hsCodeverfication&&hsCodeverfication.screeningId)
    //     }
  };

  const getHsCodesearchDetail = async (index) => {
    await fetch(`${API.BASE_URL}getScreenigTotalAALdeatil`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        ScreningId: index,
      }),
    })
      .then((response) => response.json())
      .then(async (res) => {
        if (res.status == true) {
          sethscodeData(res.hscodeData);
          setDangerousGoods(res.hazchemDetails);
          setShipperData(res.data[0].userType == "Shipper" ? res.data[0] : "");
          setconsineeData(
            res.data[1].userType == "Consignee" ? res.data[1] : ""
          );
          // setEndUserData(res.data[2].userType == 'End User' ? res.data[2] : "")

          // sethscodetype(res.hscodeData?res.hscodeData.hscodeType:'')
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    // getECCNcodesearch();
    getHsCodeSearch();
  }, []);
  const getHazchemDescription = (hazchem) => {
    if (
      typeof hazchem === "object" &&
      hazchem !== null &&
      hazchem.hasOwnProperty("goodDescription")
    ) {
      return hazchem.goodDescription;
    } else if (typeof hazchem === "string") {
      return hazchem;
    } else {
      return "";
    }
  };
  const getHazchemPic = (hazchem) => {
    if (
      typeof hazchem === "object" &&
      hazchem !== null &&
      hazchem.hasOwnProperty("Pic")
    ) {
      return hazchem.Pic;
    } else {
      return ""; // Return an empty string or a placeholder image URL if desired
    }
  };
  return (
    <>
      <Helmet bodyAttributes={{ style: "background-color : #efefef" }} />
      <section className="py-3 overview_sec">
        <Container fluid className="widthSmall">
          <Row className="mb-lg-4 mb-md-4 mb-3">
            <Col md={12}>
              <div className="requestFlex">
                <h2 className="scheading">
                  Screening Overview : Dangerous Goods
                </h2>

                <Button
                  as={Link}
                  variant="warning"
                  size="md"
                  className="btnBack"
                  to={`/screening-overview/1`}
                >
                  <Icon icon="carbon:task-view" fontSize={18} />
                  Back to Screening Overview
                </Button>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12} className="tab_link">
              {/* {hazchemDetails!='Batch Screening'?
                   <Link to="/screeningovervie  w-RPS">RPS</Link>
              
              :<>
         
              
              </>} */}
              <Link to="/screeningoverview-RPS">RPS</Link>

              <Link to="/screeningoverview-HSCodeVerification">
                HS Code Verification
              </Link>
              <Link to="/screeningoverview-HSCodeSanctions">
                HS Code Sanctions
              </Link>
              <Link to="/screeningoverview-DualUseGoods">Dual-Use Goods</Link>
              <Link to="/screeningoverview-DualUseExport">
                Re-export Country Control
              </Link>
              <Link className="active" to="/screeningoverview-DangerousGoods">
                Dangerous Goods
              </Link>
              <Link to="/screeningoverview-PrecursorChemicals">
                Precursor Chemicals
              </Link>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Table >
                <thead>
                  <tr>
                    <th>Goods Description</th>
                    <th>HS code or Taric Code (EU)</th>
                    <th>Chemical Or Dangerous Good</th>
                    <th>Dual Use Export Controlled Goods?</th>
                    <th>Label</th>
                  </tr>
                </thead>
                <tbody>
                  {hscodeData.length > 0 ? (
                    hscodeData[0].hscodeData
                      .filter((item) => item.chemicalDangerios === "Yes") // Filter items where chemicalDangerios is "Yes"
                      .map((item, index) => (
                        <tr key={index}>
                          <td>{item.goodDescription}</td>
                          <td>{item.hsCode}</td>
                          <td>{item.chemicalDangerios}</td>
                          <td>{item.DualUseExport}</td>
                          <td>
                            {item.HAZCHEM?.Pic ? (
                              <div className="text-center">
                                <Image
                                  src={`${PIC.PIC_URL}${getHazchemPic(
                                    item.HAZCHEM
                                  )}`}
                                  alt=""
                                  style={{ height: 80, width: 80 }}
                                />
                              </div>
                            ) : null}
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-center">
                    
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ScreeningOverviewDangerousGoods;
