import React, { useEffect, useState } from "react";
import { Col, Container, Image, Row, Spinner } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Helmet from "react-helmet";
import Table from "react-bootstrap/Table";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import Icon1 from "../images/dashicon1.png";
import Icon2 from "../images/dashicon2.png";
import Icon3 from "../images/dashicon3.png";
import Icon4 from "../images/dashicon4.png";
import Icon5 from "../images/upgradeicon1.png";
import Logo2 from "../images/logo2.png";
import { FaChevronDown } from "react-icons/fa6";

import EditIcon from "../images/editicon.svg";
import DeleteIcon from "../images/deleteicon.svg";
import { Link } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import * as SERVICE from "../../services/index";
import * as API from "../../services/env";
import * as PIC from "../../services/env";
const Dashboard = () => {
  const [recentList, setrecentList] = useState("");

  const [recentListCount, setrecentListCount] = useState("");
  const [noRiskCount, setnoRiskCount] = useState("");

  const [PendingCount, setPendingCount] = useState("");


  const [RiskCount, setRiskCount] = useState("");

  const [GreenCount, setGreenCount] = useState("");
  const [RedCount, setRedCount] = useState("");

  const [loader, setloader] = useState(false);

  useEffect(() => {
    const setLocalStorageData = () => {
      // Set necessary data in localStorage
      localStorage.setItem("IsLogin", true);
      localStorage.setItem("isLogin", true);
      localStorage.setItem("pageReloaded", true);
      // You may set other data here as needed
    };


    const isAllDataSet =
      localStorage.getItem("IsLogin") &&
      localStorage.getItem("isLogin") &&
      localStorage.getItem("pageReloaded");

    // If all necessary data is set, reload the page
    if (!isAllDataSet) {
      setLocalStorageData();
      // Delay the reload by a short interval to ensure data is set
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  }, []);
  useEffect(() => {
    localStorage.setItem("IsLogin", true);
    localStorage.setItem("isLogin", true);
  }, []);


  const pendingCount = async (selectedInterval) => {
    setloader(true);
    const userData = localStorage.getItem("userDetail");
    if (userData) {
      var data = JSON.parse(userData);
    }

    await fetch(`${API.BASE_URL}getPendingRecentcount`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      // mode: "cors",
      body: JSON.stringify({
        userId: data && data._id,
        selectedInterval: selectedInterval,
      }),
    })
      .then((response) => response.json())
      .then(async (res) => {
        setloader(false);
        if (res.status == true) {
          setPendingCount(res.count);
        }
      })
      .catch((error) => {});
  };

  const endRecentAPi = async () => {
    setloader(true);
    const userData = localStorage.getItem("userDetail");
    if (userData) {
      var data = JSON.parse(userData);
    }

    await fetch(`${API.BASE_URL}getRecentList`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      // mode: "cors",
      body: JSON.stringify({
        userId: data && data._id,
      }),
    })
      .then((response) => response.json())
      .then(async (res) => {
        setloader(false);
        if (res.status == true) {
          setrecentList(res.data);
        }
      })
      .catch((error) => {});
  };
  const TotalCountAPi = async (selectedInterval) => {
    const userData = localStorage.getItem("userDetail");
    if (userData) {
      var data = JSON.parse(userData);
    }

    await fetch(`${API.BASE_URL}getRecentcount`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      // mode: "cors",
      body: JSON.stringify({
        userId: data && data._id,
        selectedInterval: selectedInterval,
      }),
    })
      .then((response) => response.json())
      .then(async (res) => {

        if (res.filledresults.length > 0) {
          setRiskCount(res.filledresults[0].totalCount);
        } else {
          setRiskCount(0); // Handle case when filledresults is empty
        }
  
        if (res.data.length > 0) {
          setrecentListCount(res.data[0].totalCount);
        } else {
          setrecentListCount(0); // Handle case when data is empty
        }
  
        if (res.emptyresults.length > 0) {
          setnoRiskCount(res.emptyresults[0].totalCount);
        } else {
          setnoRiskCount(0); // Handle case when emptyresults is empty
        }
      })
      .catch((error) => {});
  };

  const endGraphApi = async () => {
    const userData = localStorage.getItem("userDetail");
    if (userData) {
      var data = JSON.parse(userData);
    }

    await fetch(`${API.BASE_URL}graphCount`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      // mode: "cors",
      body: JSON.stringify({
        userId: data && data._id,
      }),
    })
      .then((response) => response.json())
      .then(async (res) => {
        if (res.status == true) {
          setGreenCount(res.greenData);
          setRedCount(res.redData);
        }
      })
      .catch((error) => {});
  };

  const notificationCount = async () => {
    const userData = localStorage.getItem("userDetail");
    if (userData) {
      var data = JSON.parse(userData);
    }

    await fetch(`${API.BASE_URL}getNotificationCount`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      // mode: "cors",
      body: JSON.stringify({
        userid: data && data._id,
      }),
    })
      .then((response) => response.json())
      .then(async (res) => {
        if (res.status == true) {
          localStorage.setItem("notifcationCount", JSON.stringify(res.count));
        }
      })
      .catch((error) => {});
  };
  useEffect(() => {
        // localStorage.removeItem("hsCodeVerficationData");
        localStorage.removeItem("screeningId");
        localStorage.removeItem("pageReloadeds");

    notificationCount();
    endRecentAPi();
    TotalCountAPi();
    endGraphApi();
    pendingCount()
  }, []);


  useEffect(()=>{

    const screeningIds = localStorage.getItem("screeningcomplete");
    var data = JSON.parse(screeningIds);
    if(data==true||data=='true'){
      localStorage.removeItem("hsCodeVerficationData");
      localStorage.removeItem("PerShipmentShipper");
      localStorage.removeItem("PerShipmentConsignee");
      localStorage.removeItem("PerShipmentEndUser");
      localStorage.removeItem("reference");

    }
    },[])
  const [chartData, setChartData] = React.useState({
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        labels: {
          rotate: 0,
          style: {
            fontSize: "10px",
          },
        },
        categories: [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ],
      },
      stroke: {
        colors: ["transparent"],
        width: 3,
      },
      maintainAspectRatio: false,
      responsiveAnimationDuration: 500,
      legend: {
        display: false,
        showForSingleSeries: false,
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#FF1612", "#34B53A"],
      labels: {
        title: false,
      },
    },
    series: [
      {
        name: "",
        data: GreenCount.length > 0 ? GreenCount : [0],
      },
      {
        name: "",
        data: RedCount.length > 0 ? RedCount : [0],
      },
    ],
  });

  function formatTimestamp(timestamp) {
    const dateObj = new Date(timestamp);
    const formattedTime = dateObj.toLocaleTimeString([], {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
    return formattedTime;
  }
  React.useEffect(() => {
    // Update the data for RedCount and GreenCount
    setChartData((prevState) => ({
      ...prevState,
      series: [
        {
          ...prevState.series[0], // Keep the existing structure of the first series
          data: RedCount.length > 0 ? RedCount : [], // Update data for the first series
        },
        {
          ...prevState.series[1], // Keep the existing structure of the second series
          data: GreenCount.length > 0 ? GreenCount : [], // Update data for the second series
        },
      ],
    }));
  }, [RedCount, GreenCount]);

  return (
    <>
      <Helmet bodyAttributes={{ style: "background-color : #efefef" }} />
      <section className="py-3">
        <Container fluid className="widthSmall">
          <Row>
            <Col md={12}>
              <Card className="cardBox">
                <Card.Header className="d-flex align-items-center">
                  <h3>Show details of last</h3>

                  <div className="position-relative">
                    {/* <FaChevronDown className="posIocn" /> */}

                    <Form.Select
                      aria-label="Default select example"
                      onChange={async (e) => {
                        await TotalCountAPi(e.target.value);
                        await pendingCount(e.target.value);
                      }}
                      className="custom-select"
                    >
                    
                      <option value="24hrs">24hrs</option>
                      <option value="Last 7 Day">Last 7 Day</option>
                      <option value="Week">Week</option>
                      <option value="Month">Month</option>
                    </Form.Select>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col md={3}>
                      <div className="text-center progressFirst">
                        <h2>Total Screening</h2>
                        <div className="progressBox ">
                          <CircularProgressbarWithChildren
                            value={recentListCount}
                          >
                            <Image src={Icon1} alt="" />
                          </CircularProgressbarWithChildren>
                        </div>
                        <h3>{recentListCount ? recentListCount : 0}</h3>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="text-center progressSecond">
                        <h2>Pending Screening</h2>
                        <div className="progressBox ">
                        <CircularProgressbarWithChildren
                            value={PendingCount ? PendingCount : 0}
                          >
                            <Image src={Icon3} alt="" />
                          </CircularProgressbarWithChildren>
                          {/* <CircularProgressbarWithChildren value={0}>
                            <Image src={Icon2} alt="" />
                          </CircularProgressbarWithChildren> */}
                        </div>
                        <h3>{PendingCount ? PendingCount : 0}</h3>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="text-center progressThird">
                        <h2>Screening Cleared</h2>
                        <div className="progressBox ">
                          <CircularProgressbarWithChildren
                            value={noRiskCount ? noRiskCount : 0}
                          >
                            <Image src={Icon2} alt="" />
                          </CircularProgressbarWithChildren>
                        </div>
                        <h3>{noRiskCount ? noRiskCount : 0}</h3>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="text-center progressFourth">
                        <h2>Screening hit to be resolved</h2>
                        <div className="progressBox">
                          <CircularProgressbarWithChildren
                            value={RiskCount ? RiskCount : ""}
                          >
                            <Image src={Icon4} alt="" />
                          </CircularProgressbarWithChildren>
                        </div>
                        <h3>{RiskCount ? RiskCount : 0}</h3>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="py-3">
        <Container fluid className="widthSmall">
          <Row>
            <Col md={12} lg={7}>
              <Card className="cardUpgrade mb-3">
                <Card.Body>
                  <Image src={Icon5} alt="" className="icon5" />
                  <div>
                    <h3>Upgrade your Plan and Get More Requests</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur. Ut sed at magna a
                      bibendum sit malesuada a morbi. Sit convallis nullam sed
                      condimentum. Sed risus lobortis sit leo viverra
                      scelerisque blandit sed. Hac nascetur.
                    </p>
                  </div>
                  <Image src={Logo2} alt="" className="logo2" />
                </Card.Body>
              </Card>

              <h2 className="heading">Recent Requests</h2>

              <div className="table-responsive tablebox">
                {loader ? (
                  <div className="text-center centerLoader">
                    <Spinner variant="primary" animation="border" />
                  </div>
                ) : (
                  <Table bordered responsive>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Request</th>
                        <th style={{ minWidth: "110px" }}>Tittle</th>
                        <th style={{ minWidth: "120px" }}>Date</th>
                        <th>Status</th>
                        <th style={{ minWidth: "100px" }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <>
                        {recentList.length>0 ? (
                          recentList.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <Link to={`/screening-overview/${item._id}`}>
                                    {item.screeningId && item.screeningId}
                                  </Link>
                                </td>
                                <td>{item.referenceNo && item.referenceNo}</td>
                                <td>{item ? item.screeningType : "N/A"}</td>
                                <td>
                                  {item
                                    ? formatTimestamp(item.createdAt)
                                    : "N/A"}
                                </td>
                                <td>
                                  {item
                                    ? new Date(item.createdAt).toDateString()
                                    : "N/A"}
                                </td>
                                <td>
                                  <span className="pending">Pending</span>
                                </td>
                                {/* <td>
                          <div className="d-flex justify-content-between">
                            <Link>
                              <Image src={EditIcon} />
                            </Link>

                            <Link>
                              <Image src={DeleteIcon} />
                            </Link>
                          </div>
                        </td> */}
                              </tr>
                            );
                          })
                        ) : (
                          <tr className="text-center">
                            <td colSpan={6}>No Records Found</td>
                          </tr>
                        )}
                      </>

                      {/* <tr>
                    <td>23WFG</td>
                    <td>new_offer_8735</td>
                    <td>12:23 AM</td>
                    <td>17 Dec 2023</td>
                    <td>
                      <span className="rejected">Rejected</span>
                    </td>
                    <td>
                      <div className="d-flex justify-content-between">
                        <Link>
                          <Image src={EditIcon} />
                        </Link>

                        <Link>
                          <Image src={DeleteIcon} />
                        </Link>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>58OFG</td>
                    <td>spring_2020</td>
                    <td>12:23 AM</td>
                    <td>17 Dec 2023</td>
                    <td>
                      <span className="approved">Approved</span>
                    </td>
                    <td>
                      <div className="d-flex justify-content-between">
                        <Link>
                          <Image src={EditIcon} />
                        </Link>

                        <Link>
                          <Image src={DeleteIcon} />
                        </Link>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>36LFG</td>
                    <td>UK_brand_DT_campaign</td>
                    <td>12:23 AM</td>
                    <td>17 Dec 2023</td>
                    <td>
                      <span className="approved">Approved</span>
                    </td>
                    <td>
                      <div className="d-flex justify-content-between">
                        <Link>
                          <Image src={EditIcon} />
                        </Link>

                        <Link>
                          <Image src={DeleteIcon} />
                        </Link>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>99FFG</td>
                    <td>AU-DT-offer-2394</td>
                    <td>12:23 AM</td>
                    <td>17 Dec 2023</td>
                    <td>
                      <span className="pending">Pending</span>
                    </td>
                    <td>
                      <div className="d-flex justify-content-between">
                        <Link>
                          <Image src={EditIcon} />
                        </Link>

                        <Link>
                          <Image src={DeleteIcon} />
                        </Link>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>15GFG</td>
                    <td>GR-DT-brand-12</td>
                    <td>12:23 AM</td>
                    <td>17 Dec 2023</td>
                    <td>
                      <span className="approved">Approved</span>
                    </td>
                    <td>
                      <div className="d-flex justify-content-between">
                        <Link>
                          <Image src={EditIcon} />
                        </Link>

                        <Link>
                          <Image src={DeleteIcon} />
                        </Link>
                      </div>
                    </td>
                  </tr> */}
                    </tbody>
                  </Table>
                )}
              </div>
            </Col>
            <Col md={12} lg={5}>
              <div className="chartBox" style={{ height: "520px" }}>
                <h2>
                  Last 7 Days Efficiency Graph
                  <span className="approved"> Approved</span> /
                  <span className="rejected"> Rejected</span>
                </h2>
                <ReactApexChart
                  options={chartData.options}
                  series={chartData.series}
                  type="bar"
                  height={460}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Dashboard;
