import React, { useState, useEffect } from "react";

import { Col, Container, Row ,Table } from "react-bootstrap";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { Icon } from "@iconify/react";
import CustomFormIcon from "../customcomponents/CustomFormIcon";
import CustomForm from "../customcomponents/CustomForm";
import * as API from "../../services/env";

import { useParams } from "react-router-dom";


const ScreeningOverviewHSCodeVerification = () => {




  const [ShipperData, setShipperData] = useState('')
  const [consineeData, setconsineeData] = useState('')

  const [EndUserData, setEndUserData] = useState('')

  const [hazchemDetails, sethazchemDetails] = useState([])
  const [hscodeData, sethscodeData] = useState('')
  const [DangerousGoods, setDangerousGoods] = useState([])
  const getHsCodeSearch = async (req, res) => {

    const userData = localStorage.getItem("userDetail");
    const reference = localStorage.getItem("reference");
    const hscodeVerfication = localStorage.getItem("hsCodeVerficationData");
    var screeningType = localStorage.getItem("screeningType");

    sethazchemDetails(screeningType)
    const screeningId = localStorage.getItem("screeningId");
    if (userData) {
      var data = JSON.parse(userData);
      var dataReference = JSON.parse(reference);
      var hsCodeverfication = JSON.parse(hscodeVerfication);
      var screeningIds = JSON.parse(screeningId);

      getHsCodesearchDetail(screeningIds);
    }

    // if (userData) {
    //   var data = JSON.parse(userData);
    //   var dataReference = JSON.parse(reference);
    //   var hsCodeverfication = JSON.parse(hscodeVerfication);



    //   getHsCodesearchDetail(hsCodeverfication&&hsCodeverfication.screeningId)
    // }


  }


  const getHsCodesearchDetail = async (index) => {

    await fetch(`${API.BASE_URL}getScreenigTotalAALdeatil`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        ScreningId: index
      }),
    })
      .then((response) => response.json())
      .then(async (res) => {
        if (res.status == true) {

          sethscodeData(res.hazchemDetails.length>0&&res.hazchemDetails[0].hscodeType
          )
          setDangerousGoods(res.hscodeData)
          setShipperData(res.data[0].userType == 'Shipper' ? res.data[0] : "")
          setconsineeData(res.data[1].userType == 'Consignee' ? res.data[1] : "")
          setEndUserData(res.data[2].userType == 'End User' ? res.data[2] : "")

          // sethscodetype(res.hscodeData?res.hscodeData.hscodeType:'')
        }
      })
      .catch((error) => { });
  };




  useEffect(() => {
    // getECCNcodesearch();
    getHsCodeSearch()

  }, [

  ]);












  // return (
  //   <>
  //     <Helmet bodyAttributes={{ style: "background-color : #efefef" }} />
  //     <section className="py-3 overview_sec">
  //       <Container fluid className="widthSmall">
  //         <Row className="mb-lg-4 mb-md-4 mb-3">
  //           <Col md={12}>
  //             <div className="requestFlex">
  //               <h2 className="scheading">
  //                 Screening Overview : HS Code Verification
  //               </h2>

  //               <Button
  //                 as={Link}
  //                 variant="warning"
  //                 size="md"
  //                 className="btnBack"
  //                 to={`/screening-overview/1`}
  //               >
  //                 <Icon icon="carbon:task-view" fontSize={18} />
  //                 Back to Screening Overview
  //               </Button>
  //             </div>
  //           </Col>
  //         </Row>

  //         <Row>
  //           <Col md={12} className="tab_link">
  //             {/* {hazchemDetails!='Batch Screening'?
  //                  <Link to="/screeningoverview-RPS">RPS</Link>
              
  //             :<>
         
              
  //             </>} */}
  //                  <Link to="/screeningoverview-RPS">RPS</Link>

  //             <Link
  //               className="active"
  //               to="/screeningoverview-HSCodeVerification"
  //             >
  //               HS Code Verification
  //             </Link>
  //             <Link to="/screeningoverview-HSCodeSanctions">
  //               HS Code Sanctions
  //             </Link>
  //             <Link to="/screeningoverview-DualUseGoods">Dual-Use Goods</Link>
  //             <Link to="/screeningoverview-DualUseExport">
  //               Re-export Country Control
  //             </Link>
  //             <Link to="/screeningoverview-DangerousGoods">
  //               Dangerous Goods
  //             </Link>
  //             <Link to="/screeningoverview-PrecursorChemicals">
  //               Precursor Chemicals
  //             </Link>
  //           </Col>
  //         </Row>




  //         <Row>
  //           <Col md={12}>
  //             {/* <div className="d-flex align-items-center">
  //               <h2 className="rpsHeading">HS Code Verification</h2>
  //               <Button className="uploadBtn">
  //                 <Icon icon="carbon:add-filled" fontSize={18} /> File Upload
  //               </Button>
  //             </div> */}
  //             <Row className="mb-3 mb-lg-4">
  //               <Col md={12}>



  //                 {hscodeData.length>0?hscodeData.map((item,index  ) => {
  //                   return ( <div className="scree_btn scree_btnShrink" key={index}>
  //                   <CustomFormIcon
  //                     color={"#222528"}
  //                     type="text"
  //                     lableText="HS Code Search"
  //                     placeholderText="Enter HS Code Search"
  //                     value={item.hsCode}
  //                     readOnly={true}
  //                   />

  //                   <span className="orHeading mx-3"> OR </span>

  //                   <div className="scree_btn w-100 scree_btn1">
  //                     <CustomForm
  //                       color={"#222528"}
  //                       type="text"
  //                       lableText="Goods Description"
  //                       placeholderText="If HS Code is unknown enter goods description"
  //                       value={item.goodDescription  }
  //                       readOnly={true}
  //                     />
  //                   </div>
  //                   <div className="scree_btn w-100 scree_btn1 mx-3">
  //                     <CustomForm
  //                       color={"#222528"}
  //                       type="text"
  //                       lableText="Chemical Or Dangerous Good"
  //                       placeholderText="If HS Code is unknown enter goods description"
  //                       value={item.chemicalDangerios  }
  //                       readOnly={true}
  //                     />
  //                   </div>
  //                   <div className="scree_btn w-100 scree_btn1">
  //                     <CustomForm
  //                       color={"#222528"}
  //                       type="text"
  //                       lableText="Dual Use Export Controlled Goods?"
  //                       placeholderText="If HS Code is unknown enter goods description"
  //                       value={item.DualUseExport  }
  //                       readOnly={true}
  //                     />
  //                   </div>
  //                 </div>)
  //                 }):<></>}
                 
  //               </Col>
  //             </Row>
  //           </Col>
  //         </Row>
  //         {/* <Row className="mb-3 mb-lg-4">
  //           <Col md={12}>
              
  //           </Col>
  //         </Row> */}
  //       </Container>
  //     </section>
  //   </>
  // );

  return (
    <>
      <Helmet bodyAttributes={{ style: "background-color : #efefef" }} />
      <section className="py-3 overview_sec">
        <Container fluid className="widthSmall">
          <Row className="mb-lg-4 mb-md-4 mb-3">
            <Col md={12}>
              <div className="requestFlex">
                <h2 className="scheading">
                  Screening Overview: HS Code Verification
                </h2>
                <Button
                  as={Link}
                  variant="warning"
                  size="md"
                  className="btnBack"
                  to={`/screening-overview/1`}
                >
                  <Icon icon="carbon:task-view" fontSize={18} />
                  Back to Screening Overview
                </Button>
              </div>
            </Col>
          </Row>
  
          <Row>
            <Col md={12} className="tab_link">
              <Link to="/screeningoverview-RPS">RPS</Link>
              <Link className="active" to="/screeningoverview-HSCodeVerification">
                HS Code Verification
              </Link>
              <Link to="/screeningoverview-HSCodeSanctions">
                HS Code Sanctions
              </Link>
              <Link to="/screeningoverview-DualUseGoods">Dual-Use Goods</Link>
              <Link to="/screeningoverview-DualUseExport">
                Re-export Country Control
              </Link>
              <Link to="/screeningoverview-DangerousGoods">
                Dangerous Goods
              </Link>
              <Link to="/screeningoverview-PrecursorChemicals">
                Precursor Chemicals
              </Link>
            </Col>
          </Row>
  
          <Row>
            <Col md={12}>
              <Table>
                <thead>
                  <tr>
                    <th>HS Code Search</th>
                    <th>Goods Description</th>
                    <th>Chemical Or Dangerous Good</th>
                    <th>Dual Use Export Controlled Goods?</th>
                  </tr>
                </thead>
                <tbody>
                  {hscodeData.length > 0 ? (
                    hscodeData.map((item, index) => (
                      <tr key={index}>
                        <td>{item.hsCode}</td>
                        <td>{item.goodDescription}</td>
                        <td>{item.chemicalDangerios}</td>
                        <td>{item.DualUseExport}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" className="text-center">
                     
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
  
};

export default ScreeningOverviewHSCodeVerification;
