import React, { useEffect, useState } from "react";
import { Col, Container, Image, Row, Button, Toast } from "react-bootstrap";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import PlanIcon1 from "../images/planicon1.svg";
import PlanIcon2 from "../images/planicon2.svg";
import PlanIcon3 from "../images/planicon3.svg";
import PlanIcon4 from "../images/planicon4.svg";

import { ToastContainer, toast } from "react-toastify";
import Logo2 from "../images/logo2.png";
import Icon5 from "../images/upgradeicon1.png";
import * as API from "../../services/env";
import moment from "moment";
import { useNavigate, useLocation } from "react-router-dom";
const Subscription = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [userid, setuserid] = useState("");
  const [subId, setsubId] = useState("");
  const [cardDeatil, setcardDetail] = useState("");
  const [modal, setModal] = useState(false);

  const [paymentData, setpaymentData] = useState([]);

  const Cancelsubscription = async () => {
    await fetch(`${API.BASE_URL}cancelSubscripton`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      // mode: "cors",
      body: JSON.stringify({
        email: email,
      }), // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then(async(res) => {


        if (res.status == true) {

          await localStorage.setItem(
            "userDetail",
            JSON.stringify(res.updatedData)
          );
          setsubId(true);
          setModal(false);
          toast("Subscription cancelled successfully!");

          // window.location.reload();
          // setcardDetail(res.subscription);
        }
        if (res.status == false) {
      
          setModal(false);
          toast("No active subscriptions for this user");

          // window.location.reload();
          // setcardDetail(res.subscription);
        }
      })
      .catch((error) => {});
  };

  const getPaymentDetail = async (index) => {
    await fetch(`${API.BASE_URL}getPaymnetDetail`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then(async (res) => {
        if (res.status == true) {
          setpaymentData(res.activePricesWithDetails);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    const userData = localStorage.getItem("userDetail");
    if (userData) {
      var data = JSON.parse(userData);
      setEmail(data.email);
      setuserid(data._id);
      setsubId(data.packageSatus=='0'?true:false)

    }

    fetch(`${API.BASE_URL}fetchActiveSubscriptions`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      // mode: "cors",
      body: JSON.stringify({ email: data.email }), // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status == true) {
          setcardDetail(res.subscription);
        }
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    getPaymentDetail();
  }, []);


 
  return (
    <>
      <Helmet bodyAttributes={{ style: "background-color : #efefef" }} />
      <section className="py-3">
        <Container fluid className="widthSmall">
          <Row className="mb-lg-3 mb-md-3 mb-3">
            <Col
              md={12}
              className="d-flex justify-content-between align-items-center"
            >
              <h2 className="scheading">Current and Other Plans</h2>
              {subId==false&&<div>
                {/* <Link className="d-block mb-2 approved font14">
                  Change Plan
                </Link> */}

                <Link
                  className="d-block rejected font14"
                  onClick={() => setModal(true)}
                  // onClick={() => Cancelsubscription()}
                >
                  Cancel Subscription
                </Link>
              </div>}
              
            </Col>
          </Row>
          <Modal
            show={modal}
            onHide={() => setModal(false)}
            style={{ marginTop: "10%" }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Cancel Subscription!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5>Are you sure for cancel your subscription!</h5>
              <p
                style={{ "font-size": "12px" }}
              >{`By confirming your subscription will be ended on ${moment(
                cardDeatil.endDate
              ).format("LL")}`}</p>
            </Modal.Body>
            <Modal.Footer style={{ display: "flex" }}>
              <Button
                variant="secondary"
                style={{ backgroundColor: "blue" }}
                onClick={() => Cancelsubscription()}
              >
                Yes
              </Button>
              <Button variant="secondary" onClick={() => setModal(false)}>
                No
              </Button>
            </Modal.Footer>
          </Modal>

          <Row className="mb-lg-4 mb-md-4 mb-3">
            <Col md={12}>
              <div className="plan_box flex-row">
                {paymentData.map((item, index) => {
                  return (
                    <Card className="borderAdvance w30" key={index}>
                      <Card.Body>
                        <div className="side_badge">
                          <h6 className="advanceH6">
                            {item.productDetails.name}
                          </h6>
                        </div>

                  
                        {cardDeatil.amount ===
                          Number((item.unit_amount / 100).toString()) && subId==false&&(
                          <h4>
                            {`Current Plan (${
                              cardDeatil.amount ===
                              (item.unit_amount / 100).toString()
                                ? cardDeatil.interval
                                : ""
                            }) `}
                          </h4>
                        )}

                        <h2>Benefits</h2>

                        <div
                          className="col-md-12"
                          dangerouslySetInnerHTML={{
                            __html: item.productDetails.description,
                          }}
                        ></div>
                        {/* <div className="mediaBox">
                          <Image src={PlanIcon1} alt="" />
                          <div>
                            <h3>30 Requests Daily</h3>
                            <p>
                              Lorem ipsum dolor sit amet consectetur. Ut sed at
                              magna a bibendum sit
                            </p>
                          </div>
                        </div>

                        <div className="mediaBox">
                          <Image src={PlanIcon2} alt="" />
                          <div>
                            <h3>Instant Customer Support</h3>
                            <p>
                              Lorem ipsum dolor sit amet consectetur. Ut sed at
                              magna a bibendum sit
                            </p>
                          </div>
                        </div>

                        <div className="mediaBox">
                          <Image src={PlanIcon3} alt="" />
                          <div>
                            <h3>Easy Management</h3>
                            <p>
                              Lorem ipsum dolor sit amet consectetur. Ut sed at
                              magna a bibendum sit
                            </p>
                          </div>
                        </div> */}

                        <div className="mediaBox">
                          {/* <Image src={PlanIcon4} alt="" /> */}
                          <div>
                            {/* <h3>Upto 5 Users</h3>
                            <p>Lorem ipsum dolor sit amet consectetur.</p> */}
                            {cardDeatil.amount ==
                            Number((item.unit_amount / 100).toString()) && subId ==false? (
                              <div></div>
                            ) : (
                              <Button
                                className="mt-2"
                                onClick={() =>
                                  navigate("/SubscriptionUpdate", {
                                    state: {
                                      userData: userid,
                                      prize: (
                                        item.unit_amount / 100
                                      ).toString(),
                                      email: email,
                                    },
                                  })
                                }
                              >
                                Change Plan
                              </Button>
                            )}
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  );
                })}

                {/* <span className="centerChange w10">
                  Want to
                  <br />
                  Change
                  <br />
                  Plan ?
                </span> */}
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12} xl={6} lg={12} sm={12}>
              <div className="req_flex">
                <div className="request_box flexw">
                  <h2>Request Remaining</h2>
                  <div className="padNew">
                    <h3>Total : 30</h3>
                    <h4>Remaining : 30</h4>
                    <h5>Renews Daily</h5>
                  </div>
                </div>

                <div className="payment_box flexw">
                  <h2>Last payment</h2>
                  <div className="padNew">
                    <h3>{`${moment(cardDeatil.startDate).format("LL")}`}</h3>
                    <h4>{`${
                      cardDeatil.amount === 99
                        ? "ADVANCE Plan Subscription"
                        : cardDeatil.amount === 49
                        ? "PROFESSIONAL Plan Subscription"
                        : cardDeatil.amount === 19
                        ? "BASIC Plan Subscription"
                        : "No Plan Yet"
                    }`}</h4>
                    <h5>{`Billed ${cardDeatil ? cardDeatil.interval : ""}`}</h5>
                  </div>
                </div>
                <ToastContainer />
                <div className="renewal_box flexw">
                  <h2>Payment Renewal</h2>
                  <div className="padNew">
                    <h3 style={{ color: subId ? 'red' : 'green' }}>{`${
                      subId ==true
                        ? "Cancelled"
                        : moment(cardDeatil.endDate).format("LL")
                    }`}</h3>

                 {subId==false&&  <h4>{`$${cardDeatil ? cardDeatil.amount : ""}`}</h4>} 
                    <h5>{`Billed ${cardDeatil ? cardDeatil.interval : ""}`}</h5>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={12} xl={6} lg={12} sm={12}>
              <Card className="cardUpgrade mb-3">
                <Card.Body>
                  <Image src={Icon5} alt="" className="icon5" />
                  <div>
                    <h3>Upgrade your Plan and Get More Requests</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur. Ut sed at magna a
                      bibendum sit malesuada a morbi. Sit convallis nullam sed
                      condimentum. Sed risus lobortis sit leo viverra
                      scelerisque blandit sed. Hac nascetur.
                    </p>
                  </div>
                  <Image src={Logo2} alt="" className="logo2" />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Subscription;
