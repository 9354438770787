import React from "react";
import Form from "react-bootstrap/Form";

const CustomForm = ({
  controlText,
  color,
  lableText,
  placeholderText,
  type,
  value,
  onChange,
  disbaled,
  readOnly
}) => {
  return (
    <Form.Group className="mb-3" controlId={controlText}>
      <Form.Label style={{ color: color }}>{lableText}</Form.Label>
      <Form.Control
        readOnly={readOnly}
        onChange={onChange}
        disbaled={disbaled}
        value={value}
        type={type}
        placeholder={placeholderText}
        style={{
          backgroundColor: readOnly ? "#d3d3d3" : "#fff", // Grey for read-only
          color: readOnly ? "#6c757d" : "#000", // Adjust text color for contrast
          cursor: readOnly ? "not-allowed" : "text", // Indicate read-only
        }}
      />
    </Form.Group>
  );
};

export default CustomForm;
